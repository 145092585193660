import { useQuery } from '@vue/apollo-composable';
import { Ref, computed } from 'vue';
import { graphql } from '../../gql';

export const useHypixelPlayer = (playerName: Ref<string>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { result, loading } = useQuery(
    graphql(/* GraphQL */ `
      query getHypixelPlayer($playerName: ID!) {
        hypixelPlayer(playerName: $playerName) {
          uuid
          playerName
          displayName
          rank {
            displayName
            plusColor
          }
          achievements {
            totalPoints
            totalUnlocked
            oneTime {
              name
              description
              points
              game
              key
              gamePercentUnlocked
              globalPercentUnlocked
              unlocked
              legacy
              secret
              secretHint
            }
            tiered {
              name
              description
              game
              key
              legacy
              amount
              tiers {
                tier
                points
                unlocked
                amountRequired
              }
            }
          }
          karma
          networkLevel {
            exp
            level
          }
          status {
            online
            game
            mode
          }
          texture
        }
      }
    `),
    {
      playerName,
    },
    {
      pollInterval: 300 * 1000,
    },
  );

  const player = computed(() => result.value?.hypixelPlayer);

  return {
    player,
    loading,
  };
};
