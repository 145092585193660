import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { graphql } from '../../gql';

export const useGames = () => {
  const { result, loading } = useQuery(
    graphql(/* GraphQL */ `
      query getGames {
        games {
          name
          legacy
          retired
          id
          playerCount
          databaseName
          hasAchievements
          images {
            icon
            headerImage
          }
        }
      }
    `),
  );

  // const { result, loading } = useQuery(
  //   gql`
  //     query getGames {
  //       games {
  //         name
  //         legacy
  //         retired
  //         id
  //         playerCount
  //         databaseName
  //         hasAchievements
  //       }
  //     }
  //   `,
  // );

  const games = computed(() => result.value?.games || []);

  return {
    games,
    loading,
  };
};
