import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Cookie from '../views/CookiePolicy.vue';
import HypixelPlayerLayout from '../views/HypixelPlayerLayout.vue';
import HypixelPlayer from '../views/hypixelPlayer/HypixelPlayer.vue';
import Achievements from '../views/hypixelPlayer/Achievements.vue';
import AchievementsGame from '../views/hypixelPlayer/AchievementsGame.vue';
import SkyBlockPlayerLayout from '../views/SkyBlockPlayerLayout.vue';
import SkyBlockPlayer from '../views/skyBlockPlayer/SkyBlockPlayer.vue';
import SkyBlockProfileSlayer from '../views/skyBlockPlayer/Slayer.vue';
import SkyBlockProfilePet from '../views/skyBlockPlayer/Pet.vue';
import SkyBlockProfileMinion from '../views/skyBlockPlayer/Minion.vue';
import SkyBlockProfileCollection from '../views/skyBlockPlayer/Collection.vue';
import SkyBlockProfileAccessory from '../views/skyBlockPlayer/Accessory.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: Cookie,
  },
  {
    path: '/player/:playerName',
    name: 'HypixelPlayer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ '../views/HypixelPlayer.vue'),
    component: HypixelPlayerLayout,
    children: [
      {
        path: '',
        name: 'playerProfile',
        component: HypixelPlayer,
      },
      {
        path: 'achievements',
        name: 'playerAchievements',
        component: Achievements,
      },
      {
        path: 'achievements/:game',
        name: 'playerAchievementsGame',
        component: AchievementsGame,
      },
    ],
  },
  {
    path: '/skyblock/player/:playerName/:cuteName?',
    name: 'SkyBlockPlayer',
    component: SkyBlockPlayerLayout,
    children: [
      {
        path: '',
        name: 'skyBlockProfile',
        component: SkyBlockPlayer,
      },
      {
        path: 'slayer',
        name: 'skyBlockProfileSlayer',
        component: SkyBlockProfileSlayer,
      },
      {
        path: 'pet',
        name: 'skyBlockProfilePet',
        component: SkyBlockProfilePet,
      },
      {
        path: 'minion',
        name: 'skyBlockProfileMinion',
        component: SkyBlockProfileMinion,
      },
      {
        path: 'collection',
        name: 'skyBlockProfileCollection',
        component: SkyBlockProfileCollection,
      },
      {
        path: 'accessory',
        name: 'skyBlockProfileAccessory',
        component: SkyBlockProfileAccessory,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
