export const useCompactNumberFormatter = (number: number, maxFraction = 1) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: maxFraction,
    compactDisplay: 'short',
  }).format(number);
};

export const useStyleNumberFormatter = (number: number, maxFraction = 1) => {
  return Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: maxFraction,
  }).format(number);
};
