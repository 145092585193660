import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${process.env.VUE_APP_API_URI || 'http://localhost:4000'}/graphql`,
  // credentials: 'include',
});

// Cache implementation
const cache = new InMemoryCache({});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});
