import { ref, watch, computed } from 'vue';
import { useCookie } from 'vue-cookie-next';
import { optIn, optOut } from 'vue-gtag';

const allowCookies = ref<boolean>();

export default function useCookies() {
  const cookie = useCookie();

  if (cookie.isCookieAvailable('cookies_consent')) {
    allowCookies.value = cookie.getCookie('cookies_consent') === 'true';
    if (allowCookies.value) {
      optIn();
    }
  } else {
    allowCookies.value = undefined;
  }

  watch(allowCookies, () => {
    if (allowCookies.value != undefined) {
      const date = new Date();
      date.setDate(date.getDate() + 180);
      cookie.setCookie('cookies_consent', allowCookies.value.toString(), {
        expire: date,
      });
      if (allowCookies.value) {
        optIn();
      } else {
        optOut();
      }
    }
  });

  const showBanner = computed(() => {
    return allowCookies.value === undefined;
  });

  const okClicked = () => (allowCookies.value = true);

  const declinedClicked = () => (allowCookies.value = false);

  return {
    allowCookies,
    showBanner,
    okClicked,
    declinedClicked,
  };
}
