import { DefaultApolloClient } from '@vue/apollo-composable';
import { createApp, h, provide } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/style.scss';
import { apolloClient } from './ApolloClient';
import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
})
  .use(router)
  .use(VueCookieNext)
  .use(FloatingVue, {
    themes: {
      'minecraft-tooltip': {
        $extend: 'tooltip',
        $resetCss: true,
      },
    },
  })
  .use(
    VueGtag,
    {
      config: {
        id: 'G-1JYFM1T1V0',
      },
      enabled: false,
    },
    router,
  )
  .mount('#app');

// app.use(VueCookieNext);
// app.use(VueGtag, {
//   config: { id: 'G-YOUR-ID-HERE' },
//   router,
//   enabled: false,
// });

//createApp(App).use(router).mount('#app');
