/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Accessories = {
  __typename?: 'Accessories';
  accessoryCount: Scalars['Int'];
  enrichments: Array<Enrichment>;
  maxAccessoryCount: Scalars['Int'];
  missingAccessories: MissingAccessories;
  ownedAccessories: OwnedAccessories;
  recombobulatedCount: Scalars['Int'];
};

export type AchievementTier = {
  __typename?: 'AchievementTier';
  amountRequired: Scalars['Int'];
  points: Scalars['Int'];
  tier: Scalars['Int'];
  unlocked?: Maybe<Scalars['Boolean']>;
};

export type Backpack = {
  __typename?: 'Backpack';
  icon?: Maybe<InventoryItem>;
  inventory: Array<InventorySlot>;
};

export type ContextInput = {
  lang?: InputMaybe<SupportedLanguages>;
};

export type Enrichment = {
  __typename?: 'Enrichment';
  amount: Scalars['Int'];
  name: Scalars['String'];
};

export type Games = {
  __typename?: 'Games';
  databaseName?: Maybe<Scalars['String']>;
  hasAchievements?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  images: Images;
  legacy?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  playerCount?: Maybe<Scalars['Int']>;
  retired?: Maybe<Scalars['Boolean']>;
};

export type HypixelPlayer = {
  __typename?: 'HypixelPlayer';
  achievements?: Maybe<PlayerAchievements>;
  displayName: Scalars['String'];
  karma: Scalars['Int'];
  networkLevel?: Maybe<NetworkLevel>;
  playerName: Scalars['String'];
  rank: PlayerRank;
  status?: Maybe<PlayerStatus>;
  texture: Scalars['String'];
  uuid: Scalars['String'];
};

export type Images = {
  __typename?: 'Images';
  headerImage?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type Inventories = {
  __typename?: 'Inventories';
  accessoryBag: Array<InventorySlot>;
  armor: Array<InventorySlot>;
  backpack: Array<Backpack>;
  equippment: Array<InventorySlot>;
  fishingBag: Array<InventorySlot>;
  inventory: Array<InventorySlot>;
  personalVault: Array<InventorySlot>;
  potionBag: Array<InventorySlot>;
  quiver: Array<InventorySlot>;
  wardrobe: Array<InventorySlot>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  count: Scalars['Int'];
  damage: Scalars['Int'];
  displayName: Scalars['String'];
  extra?: Maybe<InventoryItemExtra>;
  itemID: Scalars['String'];
  lore: Array<Array<LoreItem>>;
  minecraftID: Scalars['String'];
  rarity: Scalars['String'];
  uuid: Scalars['String'];
};

export type InventoryItemExtra = {
  __typename?: 'InventoryItemExtra';
  anvilUses?: Maybe<Scalars['Int']>;
  enrichment?: Maybe<Scalars['String']>;
  recombobulated?: Maybe<Scalars['Boolean']>;
  reforge?: Maybe<Scalars['String']>;
};

export type InventorySlot = {
  __typename?: 'InventorySlot';
  item?: Maybe<InventoryItem>;
  slot: Scalars['Int'];
};

export type Level = {
  __typename?: 'Level';
  currentLevel: Scalars['Int'];
  maxLevel: Scalars['Int'];
  progress: Scalars['Float'];
  xp: Scalars['Int'];
  xpForNext: Scalars['Int'];
};

export type LoreItem = {
  __typename?: 'LoreItem';
  color?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  format?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum MinecraftColor {
  Aqua = 'AQUA',
  Black = 'BLACK',
  Blue = 'BLUE',
  DarkAqua = 'DARK_AQUA',
  DarkBlue = 'DARK_BLUE',
  DarkGray = 'DARK_GRAY',
  DarkGreen = 'DARK_GREEN',
  DarkPurple = 'DARK_PURPLE',
  DarkRed = 'DARK_RED',
  Gold = 'GOLD',
  Gray = 'GRAY',
  Green = 'GREEN',
  LightPurple = 'LIGHT_PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export type Minion = {
  __typename?: 'Minion';
  minions: Array<MinionDetail>;
  missingMinions: Array<MissingMinion>;
  slots: MinionSlots;
};

export type MinionDetail = {
  __typename?: 'MinionDetail';
  itemID: Scalars['String'];
  levels: Array<Scalars['Int']>;
  maxLevel: Scalars['Int'];
  maxUnlockedLevel: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type MinionSlots = {
  __typename?: 'MinionSlots';
  costToNextSlot: Scalars['Float'];
  currentSlots: Scalars['Int'];
  toNextSlot: Scalars['Int'];
};

export type MissingAccessories = {
  __typename?: 'MissingAccessories';
  accessories: Array<MissingAccessory>;
  upgrades: Array<MissingAccessory>;
};

export type MissingAccessory = {
  __typename?: 'MissingAccessory';
  ahPrice: Scalars['Int'];
  displayName: Scalars['String'];
  itemID: Scalars['String'];
  lore: Array<Array<LoreItem>>;
  note?: Maybe<Scalars['String']>;
  rarity: Scalars['String'];
  texture?: Maybe<Scalars['String']>;
};

export type MissingMinion = {
  __typename?: 'MissingMinion';
  itemID: Scalars['String'];
  level: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  type: Scalars['String'];
};

export type MissingPet = {
  __typename?: 'MissingPet';
  displayName: Scalars['String'];
  lore: Array<Array<LoreItem>>;
  rarity: Scalars['String'];
  texture: Scalars['String'];
  type: Scalars['String'];
};

export type Money = {
  __typename?: 'Money';
  bank: Scalars['Float'];
  purse: Scalars['Float'];
};

export type NetworkLevel = {
  __typename?: 'NetworkLevel';
  exp: Scalars['Int'];
  level: Scalars['Int'];
};

export type News = {
  __typename?: 'News';
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  pubDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OneTimeAchievement = {
  __typename?: 'OneTimeAchievement';
  description: Scalars['String'];
  game: Scalars['String'];
  gamePercentUnlocked?: Maybe<Scalars['Float']>;
  globalPercentUnlocked?: Maybe<Scalars['Float']>;
  key: Scalars['String'];
  legacy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  points: Scalars['Int'];
  secret?: Maybe<Scalars['Boolean']>;
  secretHint?: Maybe<Scalars['String']>;
  unlocked?: Maybe<Scalars['Boolean']>;
};

export type OwnedAccessories = {
  __typename?: 'OwnedAccessories';
  active: Array<OwnedAccessory>;
  inactive: Array<OwnedAccessory>;
};

export type OwnedAccessory = {
  __typename?: 'OwnedAccessory';
  displayName: Scalars['String'];
  extra?: Maybe<InventoryItemExtra>;
  itemID: Scalars['String'];
  lore: Array<Array<LoreItem>>;
  rarity: Scalars['String'];
  texture?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type OwnedPet = {
  __typename?: 'OwnedPet';
  active: Scalars['Boolean'];
  displayName: Scalars['String'];
  level: Level;
  lore: Array<Array<LoreItem>>;
  rarity: Scalars['String'];
  texture: Scalars['String'];
  type: Scalars['String'];
};

export type Pet = {
  __typename?: 'Pet';
  amount: PetAmount;
  missingPets: Array<MissingPet>;
  ownedPets: Array<OwnedPet>;
  petScore: PetScore;
  petScoreBonus: PetScoreBonus;
};

export type PetAmount = {
  __typename?: 'PetAmount';
  max: Scalars['Int'];
  owned: Scalars['Int'];
  progress: Scalars['Float'];
};

export type PetScore = {
  __typename?: 'PetScore';
  current: Scalars['Int'];
  max: Scalars['Int'];
  progress: Scalars['Float'];
};

export type PetScoreBonus = {
  __typename?: 'PetScoreBonus';
  magicFind: Scalars['Int'];
};

export type PlayerAchievements = {
  __typename?: 'PlayerAchievements';
  oneTime?: Maybe<Array<OneTimeAchievement>>;
  tiered?: Maybe<Array<TieredAchievement>>;
  totalPoints?: Maybe<Scalars['Int']>;
  totalUnlocked?: Maybe<Scalars['Int']>;
};

export type PlayerRank = {
  __typename?: 'PlayerRank';
  displayName: Scalars['String'];
  plusColor?: Maybe<MinecraftColor>;
};

export type PlayerStatus = {
  __typename?: 'PlayerStatus';
  game?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  games: Array<Games>;
  hypixelPlayer?: Maybe<HypixelPlayer>;
  news: Array<News>;
  skyBlockPlayer?: Maybe<SkyBlockPlayer>;
};

export type QueryHypixelPlayerArgs = {
  context?: InputMaybe<ContextInput>;
  playerName: Scalars['ID'];
};

export type QuerySkyBlockPlayerArgs = {
  context?: InputMaybe<ContextInput>;
  cuteName?: InputMaybe<Scalars['String']>;
  playerName: Scalars['ID'];
};

export type Skills = {
  __typename?: 'Skills';
  averageLevel: Scalars['Float'];
  averageLevelNoProgress: Scalars['Float'];
  levels: SkillsLevels;
  totalSkillXP: Scalars['Float'];
};

export type SkillsLevels = {
  __typename?: 'SkillsLevels';
  alchemy: SkillsLevelsDetail;
  carpentry: SkillsLevelsDetail;
  combat: SkillsLevelsDetail;
  enchanting: SkillsLevelsDetail;
  farming: SkillsLevelsDetail;
  fishing: SkillsLevelsDetail;
  foraging: SkillsLevelsDetail;
  mining: SkillsLevelsDetail;
  runecrafting: SkillsLevelsDetail;
  social: SkillsLevelsDetail;
  taming: SkillsLevelsDetail;
};

export type SkillsLevelsDetail = {
  __typename?: 'SkillsLevelsDetail';
  currentLevel: Scalars['Int'];
  levelCap?: Maybe<Scalars['Int']>;
  maxLevel: Scalars['Int'];
  progress: Scalars['Float'];
  uncappedLevel?: Maybe<Scalars['Int']>;
  xp: Scalars['Float'];
  xpCurrent: Scalars['Int'];
  xpForNext: Scalars['Int'];
};

export type SkyBlockPlayer = {
  __typename?: 'SkyBlockPlayer';
  displayName: Scalars['String'];
  playerName: Scalars['String'];
  profile?: Maybe<SkyBlockProfile>;
  rank: PlayerRank;
  status: PlayerStatus;
  texture: Scalars['String'];
  uuid: Scalars['String'];
};

export type SkyBlockProfile = {
  __typename?: 'SkyBlockProfile';
  accessory: Accessories;
  cuteName: Scalars['String'];
  items: Inventories;
  minion: Minion;
  money: Money;
  pets: Pet;
  skills: Skills;
  slayer: Slayer;
};

export type Slayer = {
  __typename?: 'Slayer';
  coinsSpent: SlayerCoinsSpent;
  slayerXP: Scalars['Int'];
  slayers: Slayers;
};

export type SlayerClaimedLevels = {
  __typename?: 'SlayerClaimedLevels';
  tier1: Scalars['Boolean'];
  tier2: Scalars['Boolean'];
  tier3: Scalars['Boolean'];
  tier4: Scalars['Boolean'];
  tier5: Scalars['Boolean'];
  tier6: Scalars['Boolean'];
  tier7: Scalars['Boolean'];
  tier8: Scalars['Boolean'];
  tier9: Scalars['Boolean'];
};

export type SlayerCoinsSpent = {
  __typename?: 'SlayerCoinsSpent';
  blaze: Scalars['Int'];
  enderman: Scalars['Int'];
  spider: Scalars['Int'];
  total: Scalars['Int'];
  wolf: Scalars['Int'];
  zombie: Scalars['Int'];
};

export type SlayerDetail = {
  __typename?: 'SlayerDetail';
  claimedLevels: SlayerClaimedLevels;
  kills: SlayerKills;
  level: Level;
  xp: Scalars['Int'];
};

export type SlayerKills = {
  __typename?: 'SlayerKills';
  tier1: Scalars['Int'];
  tier2: Scalars['Int'];
  tier3: Scalars['Int'];
  tier4: Scalars['Int'];
  tier5: Scalars['Int'];
};

export type Slayers = {
  __typename?: 'Slayers';
  blaze: SlayerDetail;
  enderman: SlayerDetail;
  spider: SlayerDetail;
  vampire: SlayerDetail;
  wolf: SlayerDetail;
  zombie: SlayerDetail;
};

export enum SupportedLanguages {
  English = 'ENGLISH',
  German = 'GERMAN',
  Korean = 'KOREAN',
}

export type TieredAchievement = {
  __typename?: 'TieredAchievement';
  amount: Scalars['Int'];
  description: Scalars['String'];
  game: Scalars['String'];
  key: Scalars['String'];
  legacy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tiers?: Maybe<Array<AchievementTier>>;
};

export type GetGamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetGamesQuery = {
  __typename?: 'Query';
  games: Array<{
    __typename?: 'Games';
    name?: string | null;
    legacy?: boolean | null;
    retired?: boolean | null;
    id: number;
    playerCount?: number | null;
    databaseName?: string | null;
    hasAchievements?: boolean | null;
    images: { __typename?: 'Images'; icon?: string | null; headerImage?: string | null };
  }>;
};

export type GetHypixelPlayerQueryVariables = Exact<{
  playerName: Scalars['ID'];
}>;

export type GetHypixelPlayerQuery = {
  __typename?: 'Query';
  hypixelPlayer?: {
    __typename?: 'HypixelPlayer';
    uuid: string;
    playerName: string;
    displayName: string;
    karma: number;
    texture: string;
    rank: { __typename?: 'PlayerRank'; displayName: string; plusColor?: MinecraftColor | null };
    achievements?: {
      __typename?: 'PlayerAchievements';
      totalPoints?: number | null;
      totalUnlocked?: number | null;
      oneTime?: Array<{
        __typename?: 'OneTimeAchievement';
        name: string;
        description: string;
        points: number;
        game: string;
        key: string;
        gamePercentUnlocked?: number | null;
        globalPercentUnlocked?: number | null;
        unlocked?: boolean | null;
        legacy?: boolean | null;
        secret?: boolean | null;
        secretHint?: string | null;
      }> | null;
      tiered?: Array<{
        __typename?: 'TieredAchievement';
        name: string;
        description: string;
        game: string;
        key: string;
        legacy?: boolean | null;
        amount: number;
        tiers?: Array<{
          __typename?: 'AchievementTier';
          tier: number;
          points: number;
          unlocked?: boolean | null;
          amountRequired: number;
        }> | null;
      }> | null;
    } | null;
    networkLevel?: { __typename?: 'NetworkLevel'; exp: number; level: number } | null;
    status?: {
      __typename?: 'PlayerStatus';
      online: boolean;
      game?: string | null;
      mode?: string | null;
    } | null;
  } | null;
};

export type InventoryItemFragment = {
  __typename?: 'InventoryItem';
  itemID: string;
  displayName: string;
  rarity: string;
  minecraftID: string;
  damage: number;
  uuid: string;
  count: number;
  lore: Array<
    Array<{
      __typename?: 'LoreItem';
      description: string;
      color?: string | null;
      format?: Array<string | null> | null;
    }>
  >;
  extra?: {
    __typename?: 'InventoryItemExtra';
    anvilUses?: number | null;
    reforge?: string | null;
    recombobulated?: boolean | null;
  } | null;
} & { ' $fragmentName'?: 'InventoryItemFragment' };

export type InventorySlotFragment = {
  __typename?: 'InventorySlot';
  slot: number;
  item?:
    | ({ __typename?: 'InventoryItem' } & {
        ' $fragmentRefs'?: { InventoryItemFragment: InventoryItemFragment };
      })
    | null;
} & { ' $fragmentName'?: 'InventorySlotFragment' };

export type SkyBlockPlayerQueryVariables = Exact<{
  playerName: Scalars['ID'];
  cuteName?: InputMaybe<Scalars['String']>;
}>;

export type SkyBlockPlayerQuery = {
  __typename?: 'Query';
  skyBlockPlayer?: {
    __typename?: 'SkyBlockPlayer';
    displayName: string;
    playerName: string;
    texture: string;
    uuid: string;
    rank: { __typename?: 'PlayerRank'; displayName: string; plusColor?: MinecraftColor | null };
    status: {
      __typename?: 'PlayerStatus';
      online: boolean;
      game?: string | null;
      mode?: string | null;
    };
    profile?: {
      __typename?: 'SkyBlockProfile';
      cuteName: string;
      slayer: {
        __typename?: 'Slayer';
        slayerXP: number;
        slayers: {
          __typename?: 'Slayers';
          zombie: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
          spider: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
          wolf: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
          enderman: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
          blaze: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
          vampire: { __typename?: 'SlayerDetail' } & {
            ' $fragmentRefs'?: { SlayerDetailFragment: SlayerDetailFragment };
          };
        };
      };
      pets: {
        __typename?: 'Pet';
        amount: { __typename?: 'PetAmount'; max: number; owned: number; progress: number };
        missingPets: Array<{
          __typename?: 'MissingPet';
          rarity: string;
          type: string;
          texture: string;
          displayName: string;
          lore: Array<
            Array<{
              __typename?: 'LoreItem';
              description: string;
              color?: string | null;
              format?: Array<string | null> | null;
            }>
          >;
        }>;
        ownedPets: Array<{
          __typename?: 'OwnedPet';
          active: boolean;
          rarity: string;
          type: string;
          texture: string;
          displayName: string;
          level: { __typename?: 'Level'; currentLevel: number };
          lore: Array<
            Array<{
              __typename?: 'LoreItem';
              description: string;
              color?: string | null;
              format?: Array<string | null> | null;
            }>
          >;
        }>;
        petScore: { __typename?: 'PetScore'; current: number; max: number; progress: number };
        petScoreBonus: { __typename?: 'PetScoreBonus'; magicFind: number };
      };
      skills: {
        __typename?: 'Skills';
        averageLevel: number;
        averageLevelNoProgress: number;
        totalSkillXP: number;
        levels: {
          __typename?: 'SkillsLevels';
          taming: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          farming: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          mining: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          combat: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          foraging: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          fishing: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          enchanting: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          alchemy: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          carpentry: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          runecrafting: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
          social: {
            __typename?: 'SkillsLevelsDetail';
            xp: number;
            currentLevel: number;
            maxLevel: number;
            xpCurrent: number;
            xpForNext: number;
            progress: number;
            levelCap?: number | null;
            uncappedLevel?: number | null;
          };
        };
      };
      money: { __typename?: 'Money'; bank: number; purse: number };
      accessory: {
        __typename?: 'Accessories';
        maxAccessoryCount: number;
        accessoryCount: number;
        recombobulatedCount: number;
        enrichments: Array<{ __typename?: 'Enrichment'; amount: number; name: string }>;
        missingAccessories: {
          __typename?: 'MissingAccessories';
          accessories: Array<{
            __typename?: 'MissingAccessory';
            displayName: string;
            itemID: string;
            texture?: string | null;
            rarity: string;
            note?: string | null;
            ahPrice: number;
            lore: Array<
              Array<{
                __typename?: 'LoreItem';
                description: string;
                color?: string | null;
                format?: Array<string | null> | null;
              }>
            >;
          }>;
          upgrades: Array<{
            __typename?: 'MissingAccessory';
            displayName: string;
            itemID: string;
            texture?: string | null;
            rarity: string;
            note?: string | null;
            ahPrice: number;
            lore: Array<
              Array<{
                __typename?: 'LoreItem';
                description: string;
                color?: string | null;
                format?: Array<string | null> | null;
              }>
            >;
          }>;
        };
        ownedAccessories: {
          __typename?: 'OwnedAccessories';
          active: Array<{
            __typename?: 'OwnedAccessory';
            displayName: string;
            itemID: string;
            texture?: string | null;
            rarity: string;
            uuid: string;
            lore: Array<
              Array<{
                __typename?: 'LoreItem';
                description: string;
                color?: string | null;
                format?: Array<string | null> | null;
              }>
            >;
            extra?: {
              __typename?: 'InventoryItemExtra';
              recombobulated?: boolean | null;
              enrichment?: string | null;
            } | null;
          }>;
          inactive: Array<{
            __typename?: 'OwnedAccessory';
            displayName: string;
            itemID: string;
            texture?: string | null;
            rarity: string;
            uuid: string;
            lore: Array<
              Array<{
                __typename?: 'LoreItem';
                description: string;
                color?: string | null;
                format?: Array<string | null> | null;
              }>
            >;
          }>;
        };
      };
      items: {
        __typename?: 'Inventories';
        armor: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        equippment: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        inventory: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        personalVault: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        wardrobe: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        quiver: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        potionBag: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        fishingBag: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        accessoryBag: Array<
          { __typename?: 'InventorySlot' } & {
            ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
          }
        >;
        backpack: Array<{
          __typename?: 'Backpack';
          icon?:
            | ({ __typename?: 'InventoryItem' } & {
                ' $fragmentRefs'?: { InventoryItemFragment: InventoryItemFragment };
              })
            | null;
          inventory: Array<
            { __typename?: 'InventorySlot' } & {
              ' $fragmentRefs'?: { InventorySlotFragment: InventorySlotFragment };
            }
          >;
        }>;
      };
      minion: {
        __typename?: 'Minion';
        minions: Array<{
          __typename?: 'MinionDetail';
          itemID: string;
          name: string;
          maxLevel: number;
          levels: Array<number>;
          maxUnlockedLevel: number;
          type: string;
        }>;
        slots: {
          __typename?: 'MinionSlots';
          currentSlots: number;
          toNextSlot: number;
          costToNextSlot: number;
        };
        missingMinions: Array<{
          __typename?: 'MissingMinion';
          itemID: string;
          name: string;
          level: number;
          type: string;
          price: number;
        }>;
      };
    } | null;
  } | null;
};

export type SlayerDetailFragment = {
  __typename?: 'SlayerDetail';
  kills: {
    __typename?: 'SlayerKills';
    tier1: number;
    tier2: number;
    tier3: number;
    tier4: number;
    tier5: number;
  };
  level: {
    __typename?: 'Level';
    currentLevel: number;
    progress: number;
    xp: number;
    xpForNext: number;
  };
} & { ' $fragmentName'?: 'SlayerDetailFragment' };

export type GetNewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNewsQuery = {
  __typename?: 'Query';
  news: Array<{
    __typename?: 'News';
    title?: string | null;
    id: string;
    content?: string | null;
    link?: string | null;
    image?: string | null;
  }>;
};

export const InventoryItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InventoryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minecraftID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'damage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'anvilUses' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reforge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recombobulated' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InventoryItemFragment, unknown>;
export const InventorySlotFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventorySlot' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InventorySlot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InventoryItem' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slot' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InventoryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minecraftID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'damage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'anvilUses' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reforge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recombobulated' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InventorySlotFragment, unknown>;
export const SlayerDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlayerDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SlayerDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tier1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier5' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'level' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currentLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xpForNext' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SlayerDetailFragment, unknown>;
export const GetGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGames' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'games' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legacy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'retired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playerCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'databaseName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAchievements' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headerImage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGamesQuery, GetGamesQueryVariables>;
export const GetHypixelPlayerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHypixelPlayer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playerName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hypixelPlayer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playerName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playerName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plusColor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'achievements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalUnlocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oneTime' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'game' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gamePercentUnlocked' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'globalPercentUnlocked' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'unlocked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legacy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'secret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'secretHint' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiered' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'game' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legacy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unlocked' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amountRequired' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'karma' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkLevel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'exp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'online' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'game' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHypixelPlayerQuery, GetHypixelPlayerQueryVariables>;
export const SkyBlockPlayerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SkyBlockPlayer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playerName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cuteName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skyBlockPlayer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playerName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playerName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cuteName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cuteName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rank' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plusColor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'online' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'game' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cuteName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slayer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'slayerXP' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slayers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zombie' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spider' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wolf' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'enderman' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'blaze' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vampire' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SlayerDetail' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'owned' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'missingPets' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lore' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'description' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ownedPets' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lore' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'description' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'format' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'petScore' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'petScoreBonus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'magicFind' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'taming' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'farming' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mining' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'combat' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'foraging' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fishing' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'enchanting' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'alchemy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carpentry' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'runecrafting' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'social' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'maxLevel' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpCurrent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'xpForNext' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'progress' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'levelCap' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'uncappedLevel' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'averageLevel' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'averageLevelNoProgress' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalSkillXP' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'money' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'purse' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxAccessoryCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accessoryCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recombobulatedCount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enrichments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'missingAccessories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accessories' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'displayName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lore' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'color' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'format' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ahPrice' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'upgrades' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'displayName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lore' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'color' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'format' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ahPrice' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ownedAccessories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'displayName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lore' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'color' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'format' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'extra' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'recombobulated' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'enrichment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'inactive' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'displayName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'texture' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lore' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'color' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'format' },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'armor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'equippment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inventory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'personalVault' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'wardrobe' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quiver' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'potionBag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fishingBag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accessoryBag' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'InventorySlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'backpack' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'icon' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'InventoryItem' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'inventory' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'InventorySlot' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxLevel' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'levels' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxUnlockedLevel' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slots' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currentSlots' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'toNextSlot' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costToNextSlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'missingMinions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InventoryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rarity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minecraftID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'damage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'format' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'anvilUses' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reforge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recombobulated' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlayerDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SlayerDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tier1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier5' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'level' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currentLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xpForNext' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventorySlot' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InventorySlot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InventoryItem' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'slot' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkyBlockPlayerQuery, SkyBlockPlayerQueryVariables>;
export const GetNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNews' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'news' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNewsQuery, GetNewsQueryVariables>;
